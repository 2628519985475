import {
  type Collage,
  Locale,
  type LocaleInfo,
  Location,
  type Maybe,
  type StickerGroup,
  type StickerOnCollage,
} from '@/graphql/generated_villus'
import { getCurrentLocale } from '@/locale'

// TODO(ayson): 等後端把 BackendLocation 刪掉統一使用 Locale 後再拿掉
export const dittoMappingBackendLocation: Record<Locale, Exclude<Location, Location.All>> = {
  [Locale.Tw]: Location.Tw,
  [Locale.Jp]: Location.Jp,
  [Locale.Th]: Location.Th,
  [Locale.Asia]: Location.Asia,
  [Locale.Io]: Location.Io,
  [Locale.Eu]: Location.Eu,
  [Locale.Uk]: Location.Uk,
  [Locale.Fr]: Location.Fr,
  [Locale.De]: Location.De,
  [Locale.Es]: Location.Es,
  [Locale.Kr]: Location.Kr,
}

type SimpleLocaleInfo = null | Pick<LocaleInfo, 'amount' | 'compareAtAmount' | 'locale'>
export function localeInfoWithCurrentLocale(
  prices: Maybe<SimpleLocaleInfo[]> | undefined,
): null | SimpleLocaleInfo {
  if (prices === null || prices === undefined) {
    return null
  }
  const currentLocale = getCurrentLocale()
  const currentPrice = prices.find((item) => item !== null && item.locale === currentLocale)
  return currentPrice ?? null
}

interface SimplePricePlan {
  localeInfo?: Array<Maybe<Pick<LocaleInfo, 'amount' | 'compareAtAmount' | 'locale'>>> | null
}

export type CollageForPricing = Pick<Collage, 'backgroundVisible'> & {
  background: null | undefined | { pricePlan?: null | SimplePricePlan }
} & {
  stickerOnCollages: Array<
    Pick<StickerOnCollage, 'visible'> & {
      stickerGroup: Pick<StickerGroup, 'id'> & {
        pricePlan?: null | SimplePricePlan
      }
    }
  >
}
export function calculateCollagePrice(
  collage: CollageForPricing,
): null | Record<'compareAtPrice' | 'price', number> {
  let price = 0
  let compareAtPrice = 0
  if (
    collage.backgroundVisible &&
    collage.background !== undefined &&
    collage.background !== null
  ) {
    const background = localeInfoWithCurrentLocale(collage.background.pricePlan?.localeInfo)
    if (background === null) {
      return null
    }
    price += background.amount
    compareAtPrice += background.compareAtAmount
  }

  // 為了讓價格去重複用的
  const groupIds = new Set()
  for (const stickerItem of collage.stickerOnCollages) {
    if (stickerItem.visible && !groupIds.has(stickerItem.stickerGroup.id)) {
      groupIds.add(stickerItem.stickerGroup.id)
      const sticker = localeInfoWithCurrentLocale(stickerItem.stickerGroup.pricePlan?.localeInfo)
      if (sticker === null) {
        return null
      }
      price += sticker.amount
      compareAtPrice += sticker.compareAtAmount
    }
  }
  return {
    price,
    compareAtPrice,
  }
}

export const backendLocation: Location = dittoMappingBackendLocation[getCurrentLocale()]

export function isLocalItem(x: { location: Location }): boolean {
  return x.location === backendLocation
}
